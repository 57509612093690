@import url("https://fonts.googleapis.com/css?family=Permanent+Marker|Rock+Salt");
.App {
  font-family: sans-serif;
  text-align: center;
}
.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  /* background-color: #212527; */
}
.player .song {
  user-select: none;
  margin: 0 20px;
}
.player .song .song__title {
  text-align: left;
  margin: 0;
  color: white;
  font-family: "Permanent Marker", cursive;
  font-weight: normal;
  font-size: 3.5em;
}
.player .song .song__title:hover {
  color: greenyellow;
}
.player .song .song__artist {
  margin: 0;
  color: white;
  font-family: "Rock Salt", cursive;
  font-weight: normal;
  font-size: 1em;
}
.player .song .song__artist:hover {
  color: greenyellow;
  cursor: pointer;
}
.player .controls {
  flex-grow: 1;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.player .player__button {
  width: fit-content;
  margin-bottom: 15px;
  background-color: transparent;
  border: none;
  margin-top: 10px;
}
.player .player__button:focus {
  outline: none;
}
.player .player__button:hover {
  cursor: pointer;
}
.player .player__button:hover svg {
  color: greenyellow;
}
.player .player__button svg {
  font-size: 4em;
  color: white;
}
.player .bar {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.player .bar .bar__time {
  color: white;
  font-size: 16px;
}
.player .bar .bar__progress {
  flex: 1;
  border-radius: 5px;
  margin: 0 20px;
  height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.player .bar .bar__progress .bar__progress__knob {
  position: relative;
  height: 16px;
  width: 16px;
  border: 1.5px solid white;
  border-radius: 50%;
  background-color: #de1b22;
}
@media screen and (max-width: 800px) {
  .player {
    flex-direction: column;
  }
  .player .controls {
    width: 100%;
    margin-top: 20px;
  }
  .player .bar {
    width: 90%;
  }
}
@media screen and (max-width: 500px) {
  .player .song .song__title {
    font-size: 2.5em;
  }
  .player .song .song__artist {
    font-size: 0.8em;
  }
}

.rate-popup .modal-content {
  border-radius: 30px;
}
.rate-popup .modal-content {
  border-radius: 30px;
  max-width: 30rem !important;
  margin: 0px auto;
  height: auto;
}

.rate-popup .modal-content {
  padding: 29px;
}

.rate-popup.invite .modal-content {
  height: auto;
}
.rate-info h4 {
  color: #545454;
  font-size: 21px;
  text-align: center;
  font-weight: 600;
  font-family: "Mukta", sans-serif;
}
.rate-info span {
  color: #ff3e45;
  display: block;
}
.btn-rate {
  background: #de1b22;
  border-radius: 25px;
  text-align: center;
  line-height: 29px;
  color: #ffffff;
  text-decoration: none;
  padding: 5px 50px;
  font-size: 15px;
  height: 38px;
  width: 100%;
}
.rate-now {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px !important;
  /* width: 100px; */
}
a.rate-now:hover {
  color: #ffffff;
}
.btn-rate:hover {
  color: #ffffff !important;
}
.btn-cancel {
  padding: 14px 0;
  color: #bcbcbc;
  text-decoration: none;
}
.star-rating svg {
  margin: 8px;
}
#invite-popup .modal-header {
  border: 0;
  padding: 0;
}
#invite-popup .modal-title {
  text-align: center;
  margin: 0px auto;
  font-weight: 600;
  font-size: 29px;
  font-family: "Mukta", sans-serif;
}
#invite-popup .modal-header .btn-close {
  padding: 0;
  margin: 0;
  color: #000000;
}
.social-link {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #e2e2e2;
}
.icon-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.modal-dialog.modal-dialog-centered.rate-popup.invite {
  border-radius: 30px;
  max-width: 400px;
  margin: 0px auto;
  height: auto;
}

.custom-name-box {
  background-color: #111111;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 1);
  border-radius: 12px;
  background: #111111;
  border-radius: 45px;
  padding: 14px 20px;
  box-shadow: -12px 5px 12px 0 rgb(14 13 13),
    5px -3px 9px 0 rgb(255 255 255 / 2%);
    width: fit-content;

}
.custom-name-box h5{
  white-space: nowrap;
}
.btn-prem {
  font-size: 14px;
  background: #de1b22;
  padding: 6px 10px;
  border-radius: 25px;
  margin-left: 6px;
}
/* CSS for payment modal  */

.free-user-info {
  background: #0a0a0a;
  border-radius: 10px;
  padding: 54px;
  max-width:300px;
  width: 100%;
  margin: auto;
}
.btn_rating a:hover {
  color: #fff !important;
}