@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  font-family: "Mukta", sans-serif;
  background: #111111;
  /* height: calc(100vh - 130px); */
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto", sans-serif;
}

p,
ul,
ol {
  font-family: "Mukta", sans-serif;
}

.line_height_increase {
  line-height: 1.5rem !important;
}

.f_14 {
  font-size: 14px;
}

.f_16 {
  font-size: 16px;
}

.f_18 {
  font-size: 18px;
}

.f_20 {
  font-size: 20px;
}

.f_22 {
  font-size: 22px;
}

.f_26 {
  font-size: 26px;
}

.f_34 {
  font-size: 34px;
  color: #111111;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
}

.f_light {
  font-weight: 100;
}

:focus {
  outline: none !important;
  box-shadow: none !important;
}

#login {
  padding: 12px 0;
}

.card.shadow {
  border-radius: 30px;
}

.login-mains {
  z-index: 1;
  position: relative;
}

.navbar-toggler-icon svg {
  stroke: #c82110;
}

.main_menu nav {
  transition: 0.4s;
}

.main_menu .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
}

.main_menu .navbar-brand {
  font-weight: 400;
  font-size: 2.25rem;
  transition: 0.4s;
}

.fixed-top {
  position: fixed !important;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  transition: 0.4s;
  z-index: 999999;
}
.nav-link-gray {
  color: #828282 !important;
}

.fixed-top:before {
  background-color: #fff;
  position: fixed;
  content: "";
  top: 0;
  width: 100%;
  height: 66px;
  z-index: -1;
  left: 0;
  right: 0;
  transition: 0.4s;
  box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.07);
}

.fixed-top.navbar.py-3 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.fixed-top .navbar-brand img {
  max-width: 120px;
}

.custom_navbar .nav-link {
  font-size: 17px;
  padding: 0px 15px !important;
  font-family: "Mukta", sans-serif;
}

.main_menu .navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.main_menu .fixed-top .navbar-nav .nav-link {
  color: #1e1d1d;
}

.nav-link.navLogin_btn {
  background-color: #de1b22;
  border-radius: 4px;
  color: #fff !important;
  padding: 5px 28px !important;
  border-radius: 50px;
}

/* .heading-image {
    top: 13%;
    left: 50%;
    opacity: 0.9;
    height: 120px;
    width: 120px;
    border-radius: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 99999;
} */

/* .login-main:before {
    content: url(../images/radio-logo.png);
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    right: 0;
    text-align: center;
    margin: 0px auto;
    display: block;
    top: 10%;
    left: 110px;
    opacity: 0.9;
    height: 120px;
    width: 120px;
    border-radius: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 99999;
} */

.card.shadow p {
  font-size: 14px;
  color: #828282;
}
.input_ps {
  position: relative;
}
.input_ps img {
  position: absolute;
  width: 15px;
  top: 14px;
  right: 19px;
}
.sign-form input[type="text"],
.sign-form input[type="password"],
.sign-form select,
.sign-form input[type="number"] {
  background: #efefef;
  opacity: 1;
  border: none;
  padding: 11px 14px;
  border-radius: 6px;
  color: #bcbcbc;
  font-size: 14px;
}

.user input[type="text"] {
  background: #efefef;
  opacity: 1;
  border: none;
  padding: 11px 14px;
  border-radius: 6px;
  color: #2e2e2e;
  font-size: 25px;
  text-align: center;
}

/* .sign-form .form-control input[type="text"],
.sign-form .form-control input[type="password"],
.sign-form .form-control select
{
    background: #EFEFEF;
    opacity: 1;
    border: none;
    padding: 11px 14px;
    border-radius: 6px;
    color: #BCBCBC;
    font-size: 14px;
} */
.main_btn {
  font-weight: 500;
  font-size: 18px;
  padding: 10px 32px;
  margin-top: 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #de1b22;
  font-family: "Mukta", sans-serif;
  text-decoration: none;
  border: none;
  width: 250px;
  display: inline-block;
  text-align: center;
}

.text-sign {
  color: #9a9a9a;
  text-decoration: none;
  font-size: 16px;
  display: block;
  text-decoration: underline;
}
.text-sign-main {
  color: #9a9a9a;
  text-decoration: none;
  font-size: 16px;
  display: block;
  text-align: center;
}
.form-control ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bcbcbc;
  font-size: 14px;
  opacity: 1;
}
.form-control ::-moz-placeholder {
  /* Firefox 19+ */
  color: #bcbcbc;
  font-size: 14px;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #bcbcbc;
  font-size: 14px;
  opacity: 1;
}
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #bcbcbc;
  font-size: 14px;
  opacity: 1;
}

.form-check label {
  display: inline-block;
  color: #2e2e2e;
  font-size: 17px;
}

.form-check input[type="radio"] {
  -webkit-appearance: none;
  width: 17px;
  height: 17px;
  border: 1px solid #de1b22;
  border-radius: 50%;
  outline: none;
  background-color: #ffffff;
}

.form-check input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
.form-check input[type="radio"]:checked:before {
  background: #de1b22;
}

#login .form-check-input:checked {
  background: none;
}
#login.forgot .login-main:before {
  top: 17%;
}
.text-sign-mains {
  color: #9a9a9a;
  text-decoration: none;
  font-size: 16px;
  display: block;
  text-align: left;
}
.text-sign-mains span {
  color: #de1b22;
  text-decoration: underline;
}

.sign-up.check .form-check-input:checked {
  background: #de1b22;
}
.sign-up.check .form-check-input:checked {
  border: none;
}

#sign-up .form-check-input:checked {
  /* background-color: #de1b22; */
  border-color: #de1b22;
}

/*Subscription section*/

.heading-sub h2 {
  color: #de1b22;
  font-size: 19px;
}

.heading-sub h2::after {
  content: "";
  width: 66px;
  height: 3px;
  display: inline-block;
  background: #de1b22;
  margin: 4px 10px;
}

.heading-sub h2::before {
  content: "";
  width: 66px;
  height: 3px;
  display: inline-block;
  background: #de1b22;
  margin: 4px 10px;
}

#pricing {
  background: #111111;
  padding: 30px 0 30px;
}

.list-group-item {
  background: none;
}

/* .subscription .card-title {
	margin-bottom: 0.5rem;
	font-size: 26px;
	color: #ffffff;
}

.subscription .list-group-item {
	background: none;
	font-family: 'Mukta', sans-serif;
	color: #BCBCBC;
	position: relative;
}

.subscription {
	background: rgb(255 255 255 / 2%);
	border-radius: 20px;
	padding: 24px 19px;
	max-width: 329px;
	display: block;
}

.subscription p {
	color: #A5A5A5;
	font-size: 14px;
	font-weight: 600;
}

.subscription .list-group-item::before {
	content: url(../images/star.svg);
	position: absolute;
	left: 0;
	top: 6px;
}

.subscription.active
{
    border: 1px solid #FF3E45;
} */
a:hover {
  color: #000000;
}

section#contact {
  background: #ffffff;
  padding: 27px 0 67px;
}

h4.success_text.text-s {
  color: #ff3131;
}
.contact_us_info span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  border-radius: 10px;
  transition: all ease-in-out 0.5s;
  background: #f9fbff;
}

.contact_text {
  font-size: 18px;
  color: #bcbcbc;
  font-weight: 300;
}
.text_gry {
  color: #565656;
}

.form-control {
  width: 100%;
  color: #212529;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 9px;
  padding: 11px;
}

.sign_up_plan.sub {
  max-width: 108px;
  text-align: left;
  padding: 9px 21px;
}

#sign-up {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: calc(100vh - 70px);
}

#sign-up-new {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100vh - 145px);
}
/* #login {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.logo-main {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99999;
  top: -28px;
}

.radio_logos {
  height: 299px;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0px auto;
}

/* section#home {
    background: #0A0A0A;
} */

/* .home-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
     flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
     -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
} */

.home-main {
  margin: 0px auto;
  display: block;
  padding: 46px 0;
}

.description-rating h1 {
  color: #dfdfdf;
  opacity: 1;
  font-size: 24px;
  font-weight: 500;
}

.ratings i.fas.fa-star {
  color: #828282;
  padding: 0 5px;
}

.ratings span {
  color: #dfdfdf;
  text-decoration: underline;
  font-size: 18px;
}

.btn_rating {
  background: #de1b22;
  box-shadow: inset 3px 3px 6px #ffffff29, 3px 3px 6px #0000001a;
  border-radius: 25px;
  opacity: 1;
  text-align: center;
  line-height: 29px;
  color: #ffffff;
  text-decoration: none;
  padding: 5px 22px;
  font-size: 12px;
  height: 38px;
  flex-shrink: 0;
}

.btn_rating a {
  color: #ffffff;
  text-decoration: none;
  font-size: 12px;
}

.play-btn .fas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 29px;
  color: #ffffff;
}

.play-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  padding: 2rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  background: #111111 0% 0% no-repeat padding-box;
  box-shadow: -3px -3px 6px #ffffff0d;
  border-radius: 45px;
  opacity: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0px auto;
}

.skip-text {
  color:#de1b22;
  cursor: pointer;
  font-weight: 500;
  background-color: transparent !important;
  text-align: end;
}

.skip-text:hover {
    color: #dd373d !important;
}

.audio-player {
  height: 50px;
  width: 460px;
  font-family: arial;
  color: white;
  font-size: 0.75em;
  overflow: hidden;
  display: grid;
  grid-template-rows: 6px auto;
  padding: 20px 0;
}
.audio-player .timeline {
  background: white;
  width: 100%;
  position: relative;
  cursor: pointer;
  box-shadow: 0 2px 10px 0 #0008;
}
.audio-player .timeline .progress {
  background: coral;
  width: 0%;
  height: 100%;
  transition: 0.25s;
}
.audio-player .controls {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 20px;
}
.audio-player .controls > * {
  display: flex;
  justify-content: center;
  align-items: center;
}
.audio-player .controls .toggle-play.play {
  cursor: pointer;
  position: relative;
  left: 0;
  height: 0;
  width: 0;
  border: 7px solid #0000;
  border-left: 13px solid white;
}
.audio-player .controls .toggle-play.play:hover {
  transform: scale(1.1);
}
.audio-player .controls .toggle-play.pause {
  height: 15px;
  width: 20px;
  cursor: pointer;
  position: relative;
}
.audio-player .controls .toggle-play.pause:before {
  position: absolute;
  top: 0;
  left: 0px;
  background: white;
  content: "";
  height: 15px;
  width: 3px;
}
.audio-player .controls .toggle-play.pause:after {
  position: absolute;
  top: 0;
  right: 8px;
  background: white;
  content: "";
  height: 15px;
  width: 3px;
}
.audio-player .controls .toggle-play.pause:hover {
  transform: scale(1.1);
}
.audio-player .controls .time {
  display: flex;
}
.audio-player .controls .time > * {
  padding: 2px;
}
.audio-player .controls .volume-container {
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.audio-player .controls .volume-container .volume-button {
  height: 26px;
  display: flex;
  align-items: center;
}
.audio-player .controls .volume-container .volume-button .volume {
  transform: scale(0.7);
}
.audio-player .controls .volume-container .volume-slider {
  position: absolute;
  left: -3px;
  top: 15px;
  z-index: -1;
  width: 0;
  height: 15px;
  background: white;
  box-shadow: 0 0 20px #000a;
  transition: 0.25s;
}

/* 
  nav.navbar.navbar-expand-sm {
    background: #111111;
    box-shadow: -3px -3px 6px #ffffff0d;
    border-radius: 45px;
} */
nav.navbar.navbar-expand-sm {
  background: #111111;
  border-radius: 45px;
  padding: 14px 0;
  box-shadow: -12px 5px 12px 0 rgb(14 13 13),
    5px -3px 9px 0 rgb(255 255 255 / 2%);
}
.navbar-expand-sm .navbar-nav .nav-link {
  text-align: center;
  letter-spacing: 0px;
  font-size: 0;
  padding: 5px 27px;
  line-height: 24px;
}

li.nav-item svg {
  margin-right: 11px;
}
li.nav-item svg {
  margin-right: 11px;
}
span.verify {
  color: #282828;
  font-size: 16px;
  font-weight: 600;
  margin-left: 4px;
}

.mbb-20 {
  margin-bottom: 30px;
}

.stream.main,
.about {
  background: rgb(0 0 0 / 10%);
  padding: 27px 9px;
  border-radius: 30px;
}

.about,
.abouts {
  background: rgb(0 0 0 / 10%);
  padding: 27px 25px;
  border-radius: 30px;
}
.about h1,
.abouts h1 {
  font-size: 24px;
  color: #dfdfdf;
  position: relative;
  left: 41%;
  top: 20px;
  transform: translate(-50%, -50%);
}
.d-flexs img {
  position: absolute;
  top: 36px;
}
.stream.main h1 {
  font-size: 24px;
  color: #dfdfdf;
  text-align: center;
}
.video_cont span {
  font-size: 16px;
  color: #dfdfdf;
}
.video_cont p {
  font-size: 11px;
  color: #bcbcbc;
}

.stream.main .list-group .border-bottom {
  border-bottom: 1px solid rgb(222 226 230 / 15%) !important;
  border-radius: 0;
}

.stream.main .list-group .badge {
  color: #dfdfdf;
}

.stream.main .list-group .form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.stream.main .form-check-input:checked[type=checkbox] {
  background-image: url(../images/red-check.svg);
  border: none;
}
.stream.main .form-check-input:checked {
  background-color: #ffffff;
  border-color: #ffffff;
}

.mb-10 {
  margin-bottom: 9em;
}

.about p {
  color: #bcbcbc;
  font-size: 15px;
  padding: 6px 10px;
}

.about h6 {
  color: #dfdfdf;
  font-size: 22px;
  padding: 27px 14px 0;
}

.image_event {
  position: relative;
  width: 100%; /* for IE 6 */
}

.image_event h2 {
  position: absolute;
  left: 13px;
  width: 54%;
  font-size: 13px;
  color: #ffffff;
  bottom: 40px;
}

.event_info p {
  color: #bcbcbc;
  font-size: 16px;
  font-weight: 200;
}

.height-self-center {
  height: 100vh;
}
.new-stream {
  position: relative;
}

#payment_popup .modal-content,
#success_popup .modal-content,
#payment_popup_rem .modal-content,
#failure_popup .modal-content {
  border-radius: 30px;
}

#payment_popup .modal-dialog,
#success_popup .modal-dialog,
#failure_popup .modal-dialog {
  max-width: 450px;
}

.fade {
  transition: opacity 0.15s linear;
  background: rgb(0 0 0 / 65%) !important;
}
h4.success_text {
  color: #219200;
}

.about input[type="text"],
.about input[type="email"],
.about select {
  background: rgb(239 239 239 / 5%);
  border: none;
  padding: 11px 14px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 14px;
  /* opacity: 0.05; */
}
.conatct_fm input[type="text"],
.conatct_fm input[type="email"],
.conatct_fm input[type="password"] {
  background: rgb(239 239 239 / 5%);
  border: none;
  padding: 11px 14px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 14px;
  /* opacity: 0.05; */
}
.conatct_fm textarea {
  border: none;
  padding: 11px 14px;
  border-radius: 6px;
  font-size: 14px;
  background: rgb(239 239 239 / 5%);
}

.conatct_fm .form-select {
  display: block;
  width: 100%;
  font-size: 14px;
  color: #bcbcbc;
  background-color: #1a1a1a;
  padding: 10px 15px;
  border: none;
}
.conatct_fm input[type="text"]::placeholder {
  /* Firefox, Chrome, Opera */
  color: #bcbcbc !important;
}
.conatct_fm input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bcbcbc !important;
}

.conatct_fm input[type="text"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bcbcbc !important;
}
.conatct_fm input[type="email"]::placeholder {
  /* Firefox, Chrome, Opera */
  color: #bcbcbc !important;
}
.conatct_fm input[type="email"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bcbcbc !important;
}

.conatct_fm input[type="email"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bcbcbc !important;
}
.conatct_fm textarea::placeholder {
  /* Firefox, Chrome, Opera */
  color: #bcbcbc !important;
}
.conatct_fm textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bcbcbc !important;
}
.conatct_fm textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bcbcbc !important;
}

/* 
.form-control:disabled, .form-control:read-only {
    background: rgb(239 239 239 / 5%);
} */

li.nav-item.active a svg path,
li.nav-item.active a:hover svg path {
  fill: #ff3e45;
}

li.nav-item.active a {
  color: #ff3e45;
}

.nav-link:hover,
.nav-link:focus {
  color: #828282;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #828282;
}
.image--cover {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 20px;
  object-fit: cover;
  object-position: center right;
  border: 4px solid rgb(255 255 255 / 12%);
}
.name_info h5,
.last_info h5 {
  font-size: 12px;
  color: #bbbbbb;
}

.name_info span {
  color: #dfdfdf;
  font-size: 15px;
}
.name_info {
  padding: 11px 0;
}

.info.profile {
  background: #000000;
  border-radius: 30px;
  padding: 32px;
  position: relative;
}
.edit_profile {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: -90px;
}
.mt-10 {
  margin-top: 7rem;
}

.info.profile .main_btn {
  width: 182px;
}

.event_content {
  position: relative;
}
.event-block {
  position: relative;
  min-height: 200px;
}
.event-block img {
  border-radius: 16px;
}
.event-detail {
  position: absolute;
  bottom: 7px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  z-index: 99999;
}
.event-block::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgb(12 12 12 / 0%),
    hsl(0deg 0% 0% / 96%)
  );
  border-radius: 17px;
}
.event_detals {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 9999;
}

.event-detail h4 {
  font-size: 14px;
  width: 300px;
  display: block;
}
.navbar.settings a.nav-link img {
  background: rgb(62 56 56 / 28%);
  border-radius: 9px;
  width: 31px;
  height: 30px;
  padding: 5px;
  margin-right: 20px;
}

.bootom_navbar .navbar.settings a.nav-link {
  color: #bcbcbc;
  font-size: 16px;
}

.navbar.settings li.nav-item {
  padding: 8px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.text-center .setting {
  width: 182px;
}

.user_inform h1 {
  font-size: 20px !important;
}

.user_inform span {
  color: #dfdfdf;
  font-size: 20px;
}
.user_inform p {
  color: #bcbcbc;
  font-size: 14px;
}

nav.navbar.settings {
  position: relative;
  display: block;
}
img.profile_img {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center right;
  border: 4px solid rgb(255 255 255 / 12%);
}

/*Responisve*/

@media (max-width: 1199px) {
  .audio-player {
    height: 50px;
    width: 380px;
  }
}
@media (max-width: 991px) {
  .login-main {
    z-index: 1;
    position: relative;
    width: 70%;
  }
  .bootom_navbar .navbar-expand-sm .navbar-nav .nav-link {
    font-size: 18px;
    padding: 1px 15px;
  }

  .about,
  .abouts {
    padding: 10px;
    border-radius: 30px;
  }
}
@media (max-width: 767px) {
  .login-main {
    width: 100%;
  }

  .home-main {
    width: 100%;
  }

  nav.navbar.navbar-expand-sm {
    width: 100%;
  }

  .radio_logos {
    width: 100%;
  }

  #sign-up {
    height: auto;
    margin: 50px 0;
  }
  #sign-up-new {
    height: auto;
  }
  .main_btn {
    width: 170px;
  }

  .audio-player {
    height: 50px;
    width: 100%;
  }

  .bootom_navbar .navbar-expand-sm .navbar-nav .nav-link {
    text-align: left;
    font-size: 0;
  }

  .description-rating h1 {
    font-size: 20px;
  }

  section#sign-up {
    margin-top: 2rem !important;
  }

  nav.navbar.navbar-expand-sm {
    background: #111111;
    padding: 14px 0;
  }

  .image--cover {
    width: 99px;
    height: 99px;
  }

  .about,
  .abouts {
    padding: 9px;
  }
  .edit_profile {
    top: -60px;
  }

  .navbar-light .navbar-toggler {
    border-color: transparent;
    display: block !important;
  }

  .bootom_navbar .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .times {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .stream.main .form-check-input {
    width: 2em;
  }
}
