@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
	font-family: 'Mukta', sans-serif;
	
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Roboto', sans-serif;
}

p,
ul,
ol {
	font-family: 'Mukta', sans-serif;
}

.line_height_increase {
	line-height: 1.5rem !important;
}



.f_14 {
	font-size: 14px;
}

.f_16 {
	font-size: 16px;
}

.f_18 {
	font-size: 18px;
}

.f_20 {
	font-size: 20px;
}

.f_22 {
	font-size: 22px;
}

.f_26 {
	font-size: 26px;
}

.f_34 {
	font-size: 34px;
	color: #111111;
	font-weight: 900;
	font-family: 'Roboto', sans-serif;
}

.f_light {
	font-weight: 100;
}

:focus {
	outline: none !important;
	box-shadow: none !important;
}


/* html,body {height: 100%;} */


/*banner start*/

.text_gray {
	color: #565656;
}
header#home {
    background: #0A0A0A;
}

body#contact {
    background: #ffffff;
}
.navbar-brand img {
    width: 230px;
}
section.banner_backimage {
	background: #F9FBFF url(../images/bg_banner.jpg) no-repeat;
	background-size: 100% 100%;
}
.left_side span {
    font-size: 26px;
    font-weight: 800;
    color: #ffffff;
}
.main_menu nav {
	transition: 0.4s;
}

.main_menu .navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 1);
}

.main_menu .navbar-brand {
	font-weight: 400;
	font-size: 2.25rem;
	transition: 0.4s;
}

.fixed-top {
    position: fixed !important;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    transition: 0.4s;
    z-index: 999999;
}

.fixed-top:before {
    background-color: #111;
    position: fixed;
    content: "";
    top: 0;
    width: 100%;
    height: 98px;
    z-index: -1;
    left: 0;
    right: 0;
    transition: 0.4s;
    box-shadow: 0 0px 5px 2px rgb(0 0 0 / 7%);
    padding: 20px 0;
}

.fixed-top.navbar.py-3 {
    padding-top: 10px !important;
    padding-bottom: 0px !important;
}

.fixed-top .navbar-brand img {
    width: 215px;
}

.custom_navbar .nav-link {
	font-size: 17px;
	padding: 0px 15px !important;
	font-family: 'Mukta', sans-serif;
}

.main_menu .navbar-light .navbar-nav .nav-link {
	color: #ffffff;
}

.main_menu .fixed-top .navbar-nav .nav-link {
    color: #ffffff;
}
.nav-link.navLogin_btn {
	background-color: #DE1B22;
	border-radius: 4px;
	color: #fff !important;
	padding: 5px 28px !important;
	border-radius: 50px;
}

.login_btn {
    font-weight: 500;
    font-size: 18px;
    padding: 10px 32px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #de1b22;
    font-family: 'Mukta', sans-serif;
    text-decoration: none;
    border: none;
    display: block;
    max-width: 160px;
}
.left_side h1 {
	color: #ffffff;
	font-size: 40px;
	font-weight: 800;
}

.cursor-pointer {
	cursor: pointer;
}

.navbar-expand-sm .navbar-nav .nav-link {
    text-align: center;
    letter-spacing: 0px;
    font-size: 18px;
    padding: 5px 27px;
    line-height: 24px;
}
.play_btn h4 {
    color: #ffffff;
}
.play_btn a {
    background: #DE1B22;
    box-shadow: inset 3px 3px 6px #ffffff29, 3px 3px 6px #0000001a;
    border-radius: 25px;
    opacity: 1;
    text-align: center;
    line-height: 29px;
    color: #ffffff;
    text-decoration: none;
    padding: 7px 24px;
    font-size: 16px;
    height: 40px;
}

.play_btn {
    margin-top: 40px;
    display: block;
}

footer li a:hover {
    color: #de1b22;
}
/*banner end*/


/*About*/

.heading-title h2 {
	color: #DE1B22;
	font-size: 19px;
}

.heading-title h2::after {
	content: "";
	width: 66px;
	height: 3px;
	display: inline-block;
	background: #111111;
	margin: 4px 10px;
}

#aboutus {
	padding: 100px 0 44px;
}

.heading-title p {
	color: #726D6D;
	font-size: 16px;
}

.about_shadow {
	background: #FFFFFF;
	box-shadow: 0px 2px 5px #4e464629;
	margin: 20px 0;
}

img.img_bg_left {
	max-width: 400px;
}


/*End about section*/


/*Subscription section*/

.heading-sub h2 {
	color: #DE1B22;
	font-size: 19px;
}

.heading-sub h2::after {
	content: "";
	width: 66px;
	height: 3px;
	display: inline-block;
	background: #DE1B22;
	margin: 4px 10px;
}

.heading-sub h2::before {
	content: "";
	width: 66px;
	height: 3px;
	display: inline-block;
	background: #DE1B22;
	margin: 4px 10px;
}

#subscribe {
	background: #111111;
	padding: 80px 0 91px;
}

.list-group-item {
	background: none;
}

.subscription .card-title {
	margin-bottom: 0.5rem;
	font-size: 26px;
	color: #ffffff;
}

.subscription .list-group-item {
	background: none;
	font-family: 'Mukta', sans-serif;
	color: #BCBCBC;
	position: relative;
}
a:hover {
    color: #111111;
}
.subscription {
    background: rgb(255 255 255 / 2%);
    border-radius: 20px;
    display: block;
    height: 420px;
    width: 330px;
    position: relative;
    transition: 0.3s;
    margin: 0px auto;
    /* border: 2px solid #de1b22; */
}

.subscription p {
	color: #A5A5A5;
	font-size: 14px;
	font-weight: 600;
}

 .subscription .list-group-item::before {
	content: url(../images/star_grey.svg);
	position: absolute;
	left: 0;
	top: 6px;
}
#payment_popup .modal-content, #success_popup .modal-content, #payment_popup_rem .modal-content, #failure_popup .modal-content {
    border-radius: 30px;
}
.sign-form input[type="text"], .sign-form input[type="password"], .sign-form select, .sign-form input[type="number"] {
    background: #EFEFEF;
    opacity: 1;
    border: none;
    padding: 11px 14px;
    border-radius: 6px;
    color: #BCBCBC;
    font-size: 14px;
}

.profile-new input[type="text"], .profile-new  input[type="password"], .profile-new  select, .profile-new  input[type="number"] {
    opacity: 1;
    border: none;
    padding: 11px 14px;
    border-radius: 6px;
    color: #BCBCBC;
    font-size: 14px;
}
.input_ps img {
    position: absolute;
    width: 15px;
    top: 14px;
    right: 19px;
}
.input_ps {
    position: relative;
}
.main_btn {
    font-weight: 500;
    font-size: 18px;
    padding: 10px 32px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #de1b22;
    font-family: 'Mukta', sans-serif;
    text-decoration: none;
    border: none;
    width: 250px;
    display: inline-block;
    text-align: center;
}

#subscri .subscription .list-group-item::before {
	content: url(../images/star_grey.svg);
	position: absolute;

	left: 0;
	top: 6px;
}
.sign_up {
	font-weight: 500;
	font-size: 18px;
	display: inline-block;
	padding: 10px 32px;
	margin-top: 30px;
	border-radius: 50px;
	transition: 0.5s;
	color: #fff;
	background: #de1b22;
	font-family: 'Mukta', sans-serif;
	text-decoration: none;
	border: none;
	width: 350px;
	display: inline-block;
}

.sign_up_plan {
    font-weight: 500;
    font-size: 17px;
    padding: 10px 32px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #de1b22;
    font-family: 'Mukta', sans-serif;
    text-decoration: none;
    border: none;
    width: 308px;
    display: inline-block;
    text-align: center;
}

.subscription .list-group-flush {
    border-radius: 0;
    padding: 20px;
}

.custom_form select.form-select {
    width: 100%;
    background-color: #fff;
    border: 1px solid #E3E3E3;
    border-radius: 9px;
    padding: 11px;
    color: #565b61!important;
}
.subscription input[type="radio"]{
    -webkit-appearance: none;
    appearance: none;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    position: absolute;
    cursor: pointer;
    outline: none;
}
.subscription  input[type="radio"]:before{
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    top: 35px;
    right: 20px;
}
.subscription  input[type="radio"]:after{
    content: "";
    position: absolute;
    height: 13px;
    width: 13px;
    background-color: transparent;
    border-radius: 50%;
    top: 39.5px;
    right: 24.5px;
}
.sign_up_plan.sub {
    width: 189px;
    text-align: left;
    padding: 13px 21px;
}
div#video-popup {
    background: rgb(33 30 30 / 26%);
    backdrop-filter: blur(1px);
}
h6.contact_texts {
    font-size: 33px;
}
/* .subscription  input[type="radio"]:hover{
    transform: scale(1.05);
} */
/* .subscription input[type="radio"]:checked {
    border: 2px solid #de1b22;
} */
/* .subscription  input[type="radio"]:checked:after{
    background-color: #478bfb;
} */


/*Subscription section*/


/*download section*/

#download {
	padding: 60px 0;
}

ul.download_cfader li {
	color: #726D6D;
	font-size: 18px;
	padding: 5px 6px;
	position: relative;
	list-style-type: none;
}

ul.download_cfader li::before {
	/* content: url(../images/arrow_bk.svg); */
	position: absolute;
	z-index: 100000;
	left: -16px;
	top: 10px;
}

/* ul.download_cfader {
	padding-left: 14px;
} */
ul.download_cfader {
    padding-left: 0;
}
#video-popup .modal-content {
    background: none;
    border: none;
}
#download {
	padding: 130px 0;
}


#payment_popup .modal-content
{
  border-radius: 30px;
}

/*end download section*/


/*footer section*/

.section_padding_edit {
	padding: 80px 0;
	background: #111111;
}

.pos-zbox {
	z-index: 999;
	position: relative;
}

footer li {
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 9px;
}
.info-p p {
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    display: block;
    padding: 20px 0;
}

a.main_btns.mx-auto {
    font-weight: 500;
    font-size: 18px;
    padding: 10px 32px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #de1b22;
    font-family: 'Mukta', sans-serif;
    text-decoration: none;
    border: none;
    width: 110px;
    display: inline-block;
    text-align: center;
    margin: 0 20px;
}
footer li a {
	color: #fff;
	text-decoration: none;
	transition: 0.4s all;
}

.footer-block .text-white {
	color: #fff !important;
	text-decoration: none;
}

i.far.fa-window-close {
    color: #ffffff;
}
/*footer section end */

.subscription-body {
    height: 77px;
}
/*responsive css*/

@media(max-width:1199px)
{
	.subscription {
		display: block;
		height: 430px;
		width: 281px;
	}
	.download_btn img {
		max-width: 160px;
	}
}
@media(max-width:991px)
{
	.main_menu .fixed-top .navbar-nav .nav-link {
		color: #ffffff;
	}
}
@media(max-width:767px) {
	.subscription {
		max-width: 100%;
	}
	.left_side h1 {
		font-size: 31px;
	}
	.download_btn img {
		max-width: 90px;
		padding-bottom: 40px;
	}

	img.img_bg_left {
		max-width: 310px;
	}
	.about_img_bg img {
		max-width: 280px;
	}
	#download {
		padding: 20px 0 20px;
	}

	.f_34 {
		font-size: 26px;
}
.heading-title .f_22 {
    font-size: 22px;
    padding: 20px 0 0;
}

.heading-sub h2 {
    font-size: 17px;
}
.heading-sub h2::before{
	display: none;
}

#pricing {
    background: #111111;
    padding: 40px 0 40px;
}

section.banner_backimage {
    background-size: cover;
}

.footer_text img {
    max-width: 134px;
}

.custom_navbar .navbar-nav {
    text-align: left;
    align-items: flex-start;
    padding: 20px 10px;
    background: #1a1919;
	font-size: 17px;
}
.custom_navbar .align-items-center {
    align-items: flex-start !important;
}
.custom_navbar .nav-link {
    font-size: 17px;
    padding: 10px 32px !important;
    font-family: 'Mukta', sans-serif;
}
.navbar-light .navbar-toggler-icon
{
	background-image: url(../images/bar_icon.png);

}
.fixed-top.navbar .navbar-toggler-icon
{
	background-image: url(../images/menu_a.png);
}
.fixed-top.navbar .navbar-toggler-icon {
	
    border: none;
}

.navbar-light .navbar-toggler {
    border-color: transparent;
}

.footer-block p.f_18.f_light {
    text-align: center;
}

.fixed-top .navbar-brand img {
    max-width: 120px;
    padding: 10px;
}
}


@media(max-width:480px)
{
	.left_side h1 {
		font-size: 24px;
	}

	.left_side h1 span {
		font-size: 19px;
	}
}